import React from "react";
import { Link, graphql } from "gatsby";

import { Base } from "../components/Base";
import { Container } from "../components/Container";
import SEO from "../components/seo";

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const defaultCoverURL = data.defaultCover && data.defaultCover.publicURL;
    const blog_posts = data.blog_posts.edges;

    const previewLarge = (title, coverPictureURL, slug, date, content) => {
      return (
        <article key={slug} className="mx-5 w-full mb-12">
          <Link to={slug}>
            <div className="w-full flex flex-col md:flex-row">
              <div className="overflow-hidden w-full bg-gray-200">
                <img
                  className="flex-none w-full h-80 md:h-112 object-cover text-center hover:scale-105 transition-transform duration-200 transform"
                  alt={title}
                  src={coverPictureURL}
                />
              </div>
              <div className="flex md:w-5/12 flex-col justify-between leading-normal md:pl-4 mt-2 md:mt-0">
                <div>
                  <p className="text-sm text-grey-dark flex items-center">
                    {date}
                  </p>
                  <div className="text-black font-bold text-3xl mb-2">
                    {title}
                  </div>
                  <p
                    className="text-grey-darker text-base"
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  ></p>
                  <p className="mt-2 text-action-500 hover:underline">
                    Lire la suite
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </article>
      );
    };
    const previewMedium = (title, coverPictureURL, slug, date, content) => {
      return (
        <article key={slug} className="px-5 md:w-1/2 w-full">
          <Link to={slug}>
            <div className="w-full flex flex-col mb-12">
              <div className="overflow-hidden md:w-full bg-gray-200">
                <img
                  className="w-full h-80 flex-none object-cover text-center overflow-hidden hover:scale-105 transition-transform duration-200 transform"
                  alt={title}
                  src={coverPictureURL}
                />
              </div>
              <div className="bg-white flex flex-col justify-between leading-normal pt-2">
                <div className="mb-8">
                  <p className="text-sm text-grey-dark flex items-center">
                    {date}
                  </p>
                  <div className="text-black font-bold text-2xl mb-2">
                    {title}
                  </div>
                  <p
                    className="text-grey-darker text-base"
                    dangerouslySetInnerHTML={{
                      __html: content.substr(0, 200) + "...",
                    }}
                  ></p>
                  <p className="mt-1 text-action-500 hover:underline">
                    Lire la suite
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </article>
      );
    };
    const previewSmall = (title, coverPictureURL, slug, date, content) => {
      return (
        <article key={slug} className="px-5 md:w-1/3 w-full">
          <Link to={slug}>
            <div className="w-full flex flex-col mb-12">
              <div className="overflow-hidden md:w-full bg-gray-200">
                <img
                  className="w-full h-80 md:h-64 flex-none object-cover text-center overflow-hidden hover:scale-105 transition-transform duration-200 transform"
                  alt={title}
                  src={coverPictureURL}
                />
              </div>
              <div className="bg-white flex flex-col justify-between leading-normal pt-2">
                <div className="mb-8">
                  <p className="text-sm text-grey-dark flex items-center">
                    {date}
                  </p>
                  <div className="text-black font-bold text-xl mb-2">
                    {title}
                  </div>
                  <p
                    className="text-grey-darker text-base"
                    dangerouslySetInnerHTML={{
                      __html: content.substr(0, 160) + "...",
                    }}
                  ></p>
                  <p className="mt-1 text-action-500 hover:underline">
                    Lire la suite
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </article>
      );
    };

    const preview = ({ node }, index) => {
      const title = node.frontmatter.title || node.fields.slug;
      const coverPictureURL =
        (node.frontmatter.cover && node.frontmatter.cover.publicURL) ||
        defaultCoverURL;

      const slug = node.fields.slug;
      const date = node.frontmatter.date;
      const content = node.frontmatter.description || node.excerpt;
      if (index === 0) {
        return previewLarge(title, coverPictureURL, slug, date, content);
      } else if (index === 1 || index === 2) {
        return previewMedium(title, coverPictureURL, slug, date, content);
      } else {
        return previewSmall(title, coverPictureURL, slug, date, content);
      }
    };

    return (
      <Base>
        <SEO title="Solution de téléexpertise - Actualités" />
        <div className="pb-16">
          <div className="py-16 bg-gray-100">
            <Container>
              <h1 className="text-4xl font-bold">Actualités</h1>
            </Container>
          </div>
        </div>
        <Container>
          <div className="-ml-4 md:flex">
            <div className="pl-4">
              <div className="flex flex-wrap -mx-5">
                {blog_posts.map((post, index) => {
                  return preview(post, index);
                })}
              </div>
            </div>
          </div>
        </Container>
      </Base>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    defaultCover: file(
      relativePath: { regex: "/default_article_image.jpg/gm" }
    ) {
      publicURL
    }
    blog_posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "//actualites/[^/]+/[^/]+.md$/" } }
      limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 220)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "fr")
            title
            description
            cover {
              publicURL
            }
          }
        }
      }
    }
  }
`;
